body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #121212; /* Global dark background */
  color: #fff; /* Default text color */
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
