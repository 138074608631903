/* Overall container for the dashboard */
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    padding: 20px;
    background-color: #121212; /* Dark background */
    color: #ae2c2c;
    min-height: 100vh;
  }
  .content-display {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #1c1c1c;
    color: #853333;
    font-size: 1.2rem;
    text-align: left;
    min-height: 100px;
  }
  
  /* Header style */
  .dashboard-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Grid layout for buttons */
  .buttons-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two buttons per row */
    gap: 10px; /* Spacing between buttons */
    width: 100%;
    max-width: 400px; /* Restrict max width */
  }
  
  /* Button styles */
  .dashboard-button {
    background-color: #ff6600;
    color: #fff;
    font-size: 0.9rem;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .dashboard-button:hover {
    background-color: #cc5200;
  }
  
  /* Mobile view adjustments (optional) */
  @media (max-width: 768px) {
    .buttons-grid {
      grid-template-columns: 1fr 1fr; /* Still two buttons per row for smaller screens */
    }
  
    .dashboard-button {
      font-size: 1rem;
      padding: 12px;
    }
  }
  