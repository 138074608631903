/* SignInData container */
.signin-data-container {
  background-color: #1c1c1c;
  color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  max-width: 400px; /* Reduced width for a more compact card */
  margin: 20px auto;
  text-align: center;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Heading style */
.signin-data-container h2 {
  font-size: 1.6rem;
  margin-bottom: 15px;
  color: #ff6600;
}

/* Button style */
.signin-button {
  background-color: #ff6600;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
}

.signin-button:hover {
  background-color: #cc5200;
}

.signin-button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

/* Error message style */
.error-message {
  color: #ff4d4d;
  font-size: 1rem;
  margin-top: 15px;
}

/* User info section */
.user-info {
  background-color: #2c2c2c;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  text-align: left;
  font-size: 1rem;
}

/* User info row styling (address, referral code) */
.user-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap; /* Allow items to wrap for mobile */
}

.user-info-row p {
  flex-grow: 1;
  text-align: left;
  margin-right: 10px; /* Ensure there is space on the right */
}

.user-info-row button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 6px 12px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.user-info-row button:hover {
  background-color: #45a049;
}

.user-info-row button:focus {
  outline: none;
}

/* Styling for the address text */
.address-text {
  word-wrap: break-word; /* Breaks long addresses into multiple lines */
  width: 100%; /* Ensure it takes full width inside its container */
  margin-top: 8px;
  font-size: 1rem;
  word-break: break-word; /* Ensures no overflow */
  padding: 5px; /* Added padding to prevent text from touching edges */
}

/* Styling for the copy button */
.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4caf50;
  font-size: 1.3rem;
}

.copy-button:hover {
  color: #388e3c;
}
.success-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}
/* Mobile view adjustments */
@media (max-width: 768px) {
  .signin-data-container {
    padding: 12px;
    max-width: 100%;
  }

  .signin-button {
    padding: 8px 18px;
    font-size: 1rem;
  }

  .user-info {
    padding: 12px;
    font-size: 0.95rem;
  }

  .user-info-row {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .user-info-row button {
    margin-top: 10px;
    width: 100%;
  }

  .address-text {
    font-size: 0.95rem;
    padding: 10px;
  }

  .copy-button {
    font-size: 1.1rem;
  }
}
