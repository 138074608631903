/* General container styles */
.deposit-history-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  /* Styles for deposit history list */
  .deposit-history-list {
    margin-top: 20px;
  }
  
  /* Individual deposit item */
  .deposit-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    font-size: 16px; /* Ensuring readable text size */
  }
  
  /* Error message */
  .error-message {
    color: red;
    font-weight: bold;
    font-size: 14px; /* Smaller font for error on mobile */
  }
  
  /* Heading style */
  h2 {
    color: #333;
    font-size: 24px; /* Larger font for desktop */
    text-align: center; /* Center heading */
  }
  
  /* Media query for mobile screens */
  @media (max-width: 600px) {
    .deposit-history-container {
      padding: 15px; /* Reduce padding on mobile */
    }
  
    .deposit-item {
      padding: 8px 0; /* Reduce padding for items */
      font-size: 14px; /* Smaller font for mobile */
    }
  
    h2 {
      font-size: 20px; /* Smaller heading on mobile */
      margin-bottom: 10px;
    }
  
    .error-message {
      font-size: 12px; /* Even smaller error message text */
    }
  
    .deposit-history-list {
      padding: 0 10px; /* Add horizontal padding for mobile */
    }
  
    /* Ensure deposit item content is stacked */
    .deposit-item p {
      word-wrap: break-word;
      margin: 5px 0;
    }
  }
  
  /* Media query for tablet screens */
  @media (max-width: 768px) {
    .deposit-history-container {
      padding: 18px; /* Slightly smaller padding for tablets */
    }
  
    .deposit-item {
      padding: 10px 0;
    }
  
    h2 {
      font-size: 22px;
    }
  
    .deposit-history-list {
      padding: 0 15px; /* Add padding for tablet screens */
    }
  }
  